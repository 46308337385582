import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import InfoBlocks from "../components/Index/InfoBlocks"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import IntroHostingPlansParams from "../components/HostingPlans/IntroHostingPlansParams"
import PromoInfoRowParams from "../components/PromoInfoRow/PromoInfoRowParams"
import SiteBenefitsParams from "../components/SiteBenefits/SiteBenefitsParams"
import CustomerSupportParams from "../components/Index/CustomerSupportParams"
import FreeHosting from "../components/Common/FreeHosting";
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";
import hostingImg from "../assets/images/hosting-discount.png";


const MagentoSsdHosting = () => {
    return (
        <Layout>
            <SEO title="Magento hosting - Sitebunker.net - Designed for performant online shops"
                 description="Are you looking for Magento Hosting for your online shop? Check SiteBunker subscriptions, with advanced security and premium resources included."
            />
            <Navbar/>
            <IntroHostingBgWhite
                title={'High Efficiency Magento Web Hosting'}
                subtitle={'Discover the resources of our hosting packages and choose the right one for your online shop.'}
            />


            <HostingPlanBlock category="Magento EN"
                              discount='30'
                              showPromos={false}/>
            <BannerArea
                pageTitle="Magento Hosting designed for success"
                pageSubtitle="LiteMage Cache Server guaranteed"
                pageDescription="Enjoy increased performance and scale up your online sales with the Magento hosting subscriptions offered by SiteBunker."
                svgIllustration="magento"
            />
            <FreeHosting image={hostingImg}
                         title='Choose a performant hosting with a great support'
                         text1='Choose a one-year subscription for Magento, get 30% discount and enjoy the advantages of high-quality hosting from Sitebunker.net.'
                         text2='The offer applies to all of the above packages.'/>
            <PromoInfoRowParams
                title1="Performance & Security"
                title2="Customized Magento hosting"
                title3="FREE migration without downtime"
                paragraph1="Enjoy the benefits of the specialized cache solution provided by LiteMage, performant upload speed, advanced anti-hacking protection assured by Imunify360 and efficient security against DDoS attacks through the newly implemented Arbor solution."
                paragraph2="Regardless of the needed hosting resources, contact SiteBunker support team for any tailored made Magento hosting subscriptions. We also offer efficient VPS Hosting packages. Find out more about it"
                buttonHere="here."
                paragraph3="At SiteBunker you have an entire team of professionals at your disposal, ready to take care for free about the needed migration, without downtime, whenever you take the decision to change the current hosting provider."
            />
            <InfoBlocks/>
            <ClientFeedback
                title="Here is why you should choose "
                bold="SiteBunker Magento hosting services!"/>
            <SiteBenefitsParams
                mainTitle="MAGENTO HOSTING WITH PREMIUM RESOURCES INCLUDED"
                title1="FREE SSL CERTIFICATE"
                title2="AUTOMATIC DAILY BACKUP"
                title3="24/7 SUPPORT"
                text1="All Magento hosting subscriptions offered by SiteBunker include free SSL certificate and advanced anti-hacking protection Imunify360."
                text2="Rest assured. Your data is safe. All SiteBunker subscriptions benefit from FREE daily backup, provided in an external location."
                text3="Enjoy the 24/7 technical assistance. The customer support team is ready to assist you efficiently in any technical situation you encounter."
            />
            <CustomerSupportParams
                title="Customized web hosting solutions."
                paragraph1="Do you process a large number of actions per second?"
                paragraph2="SiteBunker offers VPS hosting services powered by AMD EPYC processors with NVMe full-SSD storage in RAID 10."
                buttonText="Ask for details here"
            />
            <Footer/>
        </Layout>
    )
}

export default MagentoSsdHosting
